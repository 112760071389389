import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../layouts/mainLayout';

import { Wrapper } from '../styles/pages/tile-and-grout-cleaning';

export const query = graphql`
  query tileAndGroutCleaningPageQuery {
    tileImage: file(relativePath: { regex: "/tile-cleaning-demo.jpg/" }) {
      childImageSharp {
        fixed(width: 326, height: 245) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const TileAndGroutCleaningPage = ({ data }) => (
  <Layout>
    <Wrapper>
      <h1 className="underlined">Tile And Grout Cleaning Service</h1>
      <p>
        Our firm is certified to take care of your hard surface flooring. We can
        accomplish this with our state-of-the-art equipment and trained
        technicians. We will be more than happy to provide you with a free quote
        and a demonstration of this service.
      </p>
      <Img
        fixed={data.tileImage.childImageSharp.fixed}
        alt="Tile and grout cleaning"
      />
      <h2>The Stone/Tile Cleaning Process</h2>
      <p>
        Let Sci-Tech’s Stone, Tile and Grout Cleaning service take one more job
        off your hands (and knees). We use a powerful, yet safe and very
        effective, cleaning process. Using a specially developed cleaning
        solution and high-pressure, hot water rinse within a fully enclosed
        vacuum system, we can restore the gleam to your stone or ceramic tile
        and grout surfaces. Using the latest in cleaning technology our method
        pressure washes the surface while simultaneously extracting all the
        built up grime. There is no over spray, so your cabinets, fixtures and
        carpeted areas remain protected, along with your surrounding
        environment.
      </p>
      <h2>The Sealer</h2>
      <p>
        We recommend using our high quality sealer on your stone, tile and grout
        after it has been cleaned. This protective barrier penetrates and blocks
        the surface of the grout or stone effectively resisting the penetration
        of foreign materials making it much easier to keep clean. And sealer
        minimizes the likelihood of staining and increases the time available to
        pickup the staining materials before it can become permanent. Sealing
        simply helps keep your flooring looking it’s best much longer.
      </p>
      <h2>Ceramic Tile & Grout Cleaning Process</h2>
      <h4>Pre-Inspection:</h4>
      <p>
        Technicians will make note of all challenging areas and set your
        expectations as well as come up with a solution for each area.
      </p>
      <h4>Area Preparation:</h4>
      <p>
        We will prepare the areas surrounding the tile, making sure not to
        disturb any carpeted or hardwood floors. At this point all furniture
        that will be cleaned under is moved.
      </p>
      <h4>Dry soil removal:</h4>
      <p>
        Our technicians thoroughly sweep and vacuum the entire area, maximizing
        the results we obtain. As in any cleaning process, dry soil removal is
        the most important.
      </p>
      <h4>Soil Suspension:</h4>
      <p>
        We use the highest quality products. The cleaning agent sprayed over the
        entire surface of your tile is the most scientifically advanced tile
        cleaner on the market.
      </p>
      <h4>Cleaning:</h4>
      <p>
        Complex and difficult areas are agitated manually with a brush and for
        really challenging heavy use areas, a mechanical rotating brush is
        employed. The entire floor is then cleaned using a state of the art
        self-contained pressure cleaning system known as the SX-12 floor
        cleaning tool.
      </p>
      <h4>Rinsing:</h4>
      <p>
        A rinse solution is pumped through the machine during cleaning,
        sanitizing and balancing the pH of your floor at 7.
      </p>
      <h4>Sealing/Finishing:</h4>
      <p>
        The type of sealer and application varies on what surface it is being
        applied to. (If grout is in need of colorizing, this is done before the
        sealing process).
      </p>
      <h4>Post-inspection:</h4>
      <p>
        Technicians will initiate a walk through and go over results before
        leaving.
      </p>
    </Wrapper>
  </Layout>
);

export default TileAndGroutCleaningPage;

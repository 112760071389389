import Styled from 'styled-components';

import { baseMixin } from '../mixins';

const Wrapper = Styled.div`
  ${baseMixin};

  h4 {
    margin-bottom: 10px;
  }
`;

export { Wrapper };
